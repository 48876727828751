import { JobRequestTypes } from "_types/JobRequestTypes";

export const ActionCreators: any = {
  getApprovalsData: (id: string) => ({
    types: [
      JobRequestTypes.GET_APPROVALS_DATA_REQUESTED,
      JobRequestTypes.GET_APPROVALS_DATA_SUCCESS,
      JobRequestTypes.GET_APPROVALS_DATA_FAILURE,
    ],
    promise: (client: any) => client.get(`get_approvals_data/${id}`, {}, "admin_backend"),
  }),

  getCompareQuotation: (jobId?: string) => {
    return {
      types: [
        JobRequestTypes.JOB_REQUEST_REQUEST,
        JobRequestTypes.GET_COMPARE_QUOTATION_SUCCESS,
        JobRequestTypes.JOB_REQUEST_FAILURE,
      ],
      promise: (client: any) => client.get(`get_compare_quotation/${jobId}`, {}, "admin_backend"),
    };
  },
  updateClientRequest: (data: any) => {
    return {
      types: [
        JobRequestTypes.JOB_REQUEST_REQUEST,
        JobRequestTypes.UPDATE_CLIENT_REQUEST_SUCCESS,
        JobRequestTypes.JOB_REQUEST_FAILURE,
      ],
      promise: (client: any) => client.put(`update_client_request`, { data }, "admin_backend"),
    };
  },
  uploadQuoteComparison: (data: any) => {
    return {
      types: [
        JobRequestTypes.JOB_REQUEST_REQUEST,
        JobRequestTypes.UPLOAD_QUOTE_COMPARISON,
        JobRequestTypes.JOB_REQUEST_FAILURE,
      ],
      promise: (client: any) => client.post(`upload_quote_comparison`, { data }),
    };
  },

  getAllCustomReports: (param: string) => {
    return {
      types: [
        JobRequestTypes.GET_ALL_CUSTOM_REPORTS_REQUESTED,
        JobRequestTypes.GET_ALL_CUSTOM_REPORTS_SUCCESS,
        JobRequestTypes.GET_ALL_CUSTOM_REPORTS_FAILURE,
      ],
      promise: (client: any) => client.get(`get_all_custom_reports/${param}`, {}, "admin_backend"),
    };
  },
  resetCustomReports: () => ({
    type: JobRequestTypes.RESET_CUSTOM_REPORTS
  }),
  updateLastActivityDate: (jobId: string) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST, 
      JobRequestTypes.UPDATE_LAST_ACTIVITY_DATE, 
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.patch(`update_last_activity_date/${jobId}`),
  }),
  publishDocs: (data: string) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST, 
      JobRequestTypes.UPDATE_LAST_ACTIVITY_DATE, 
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.patch(`admin_publish_docs`,{data}),
  }),
  getJobQuotations: (jobRequestId: string) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST, 
      JobRequestTypes.GET_JOB_QUOTATIONS, 
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.get(`get_job_quotations/${jobRequestId}`,{}),
  }),
  postFilesForCompareBoq: (data:any) =>({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST, 
      JobRequestTypes.POST_BOQ_TO_COMPARE, 
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.patch(`post_boq_to_compare`,{data}),
  }),
  inspectBOQ: (data: any) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST,
      JobRequestTypes.INSPECT_BOQ,
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.post(`inspect_boq`, { data }),
  }),

  quoteComparison: (data: any, payload: any) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST,
      JobRequestTypes.QUOTE_COMPARISON,
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.post(`quote_comparison`, { data, payload }),
  }),
  getComparisonById: (id: string) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.get(`get_comparison_by_id/${id}`),
  }),

  updateComparisonJobId: (id: string, data: any,) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.patch(`update_comparison_job_id/${id}`, { data }),
  }),

  updateVendorQuote: (data: any) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.post(`update_vendor_quote`, { data }),
  }),
  archiveVendor: (comparisonId: string, vendorId: string) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.get(`archive_vendor/${comparisonId}/${vendorId}`),
  }),

  includeVersion: (comparisonId: string, vendorId: string, versionId: string) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.get(`include_version/${comparisonId}/${vendorId}/${versionId}`),
  }),

  excludeVersion: (comparisonId: string, vendorId: string, versionId: string) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.get(`exclude_version/${comparisonId}/${vendorId}/${versionId}`),
  }),

  unArchiveVendor: (comparisonId: string, vendorId: string) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.get(`unarchive_vendor/${comparisonId}/${vendorId}`),
  }),

  addNewVendorQuotes: (data: any, payload: any) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.post(`add_new_vendor_quotes`, { data, payload }),
  }),

  recreateComparison: (data: any) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.post(`recreate_comparison`, { data }),
  }),


  makeSheetEditable: (data: any) => ({
    types: [JobRequestTypes.JOB_REQUEST_REQUEST,
    JobRequestTypes.QUOTE_COMPARISON,
    JobRequestTypes.JOB_REQUEST_FAILURE],
    promise: (client: any) => client.post(`make_sheet_editable`, { data }),
  }),

  postFilesForUnstructuredComparison: (data: any) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST,
      JobRequestTypes.POST_UNSTRUCTURED_FILES_TO_COMPARE,
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.patch(`post_unstructured_files_to_compare`, { data }),
  }),
  getComparisonFile: (data: any) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST,
      JobRequestTypes.POST_UNSTRUCTURED_FILES_TO_COMPARE,
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.post("get_comparison_table", { data })
  }),

  getAllJobIds: (data: any) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST,
      JobRequestTypes.POST_UNSTRUCTURED_FILES_TO_COMPARE,
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.post("get_all_job_ids", { data })
  }),

  getColumns: (data: any, payload: any) => (
    {
      types: [
        JobRequestTypes.JOB_REQUEST_REQUEST,
        JobRequestTypes.POST_UNSTRUCTURED_FILES_TO_COMPARE,
        JobRequestTypes.JOB_REQUEST_FAILURE
      ],
      promise: (client: any) => client.post(`get_columns`, { data, payload })
    }),

  getAutomationData: () => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST,
      JobRequestTypes.POST_UNSTRUCTURED_FILES_TO_COMPARE,
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.get("get_automation_history")
  }),
  runAutomation: () => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST,
      JobRequestTypes.POST_UNSTRUCTURED_FILES_TO_COMPARE,
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.post("quote_comparison_automation")
  }),

  getDownloadableLink: (data:any) =>({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST, 
      JobRequestTypes.POST_BOQ_TO_COMPARE, 
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.patch(`get_downloadable_link`,{data}),
  }),
  getJobDocs: (data: any) => ({
    types: [
      JobRequestTypes.JOB_REQUEST_REQUEST, 
      JobRequestTypes.GET_JOB_DOCS, 
      JobRequestTypes.JOB_REQUEST_FAILURE
    ],
    promise: (client: any) => client.post(`get_job_docs`,{data}),
  })
};

export default ActionCreators;
