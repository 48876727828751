import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import ForumIcon from "@material-ui/icons/Forum";
import MenuIcon from "@material-ui/icons/Menu";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import TagIcon from "@mui/icons-material/Tag";
import SettingsIcon from '@mui/icons-material/Settings';
import TableChartIcon from '@mui/icons-material/TableChart';
import MessageIcon from "@mui/icons-material/Message";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AnalyticsActions } from "../../container/analytics/actions";
import WrappedRoutes from "../../routesWrapper/routesWrapper";
import { getUserRole, setLogout } from "../../Utils/Storage";
import PieChartIcon from "@material-ui/icons/PieChart";
import ROLES from "../../constants/RolesConstants";
import { NAVIGATIONS } from "constants/navigation.constant";
import { ClientRequestActions } from "container/ClientRequests/actions";
import FolderSharedIcon from '@mui/icons-material/FolderShared';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    overflow: "hidden"
  },
  appBar: {
    backgroundColor: "#2a2b2c",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: "#2a2b2c",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    minHeight: "770px",
    padding: "5px 0 24px",
  },
  toolbarButtons: {
    marginLeft: "auto",
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [headerName, setHeaderName] = React.useState("Vendors");
  const userRole = getUserRole()?.split(',') || [];
  const navLinks = [
    {
      label: NAVIGATIONS.VENDORS,
      icon: <AssignmentIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_VIEW || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_EXPORT || role === ROLES.VENDOR_PUBLISH || role === ROLES.AGENT_VENDOR).length > 0
    },
    {
      label: NAVIGATIONS.CLIENTS,
      icon: <SupervisorAccountIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_VIEW || role === ROLES.CLIENT_EDIT || role === ROLES.CLIENT_EXPORT || role === ROLES.CLIENT_PUBLISH).length > 0
    },
    {
      label: NAVIGATIONS.CLIENT_REQUESTS,
      icon: <img src="/images/client_project.svg" style={{width:'2em', height:'2em'}}/>,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_PROJECTS || role === ROLES.CLIENT_VR_VIEW_EXPORT).length > 0
    },
    {
      label: NAVIGATIONS.CLIENT_ANALYTICS,
      icon: <AssessmentIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_VIEW || role === ROLES.CLIENT_EDIT || role === ROLES.CLIENT_EXPORT || role === ROLES.CLIENT_PUBLISH).length > 0
    },
    {
      label: NAVIGATIONS.VENDOR_ANALYTICS,
      icon: <PieChartIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_VIEW || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_EXPORT || role === ROLES.VENDOR_PUBLISH).length > 0
    },
    {
      label: NAVIGATIONS.COMMUNICATIONS,
      icon: <ForumIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_CLIENT_COMMUNICATION).length > 0
    },
    { 
      label: NAVIGATIONS.MESSAGING, 
      icon: <MessageIcon />,
      visibility : userRole.filter((role) =>  role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN_MESSAGING).length > 0 
    },
    {
      label: NAVIGATIONS.USER_INVITES,
      icon: <AddAlertIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.CLIENT_VIEW).length > 0
    },
    {
      label:  NAVIGATIONS.TAGS,
      icon: <TagIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_VIEW || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_EXPORT || role === ROLES.VENDOR_PUBLISH).length > 0
    },
    {
      label:  NAVIGATIONS.SETTINGS,
      icon: <SettingsIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_VIEW || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_EXPORT || role === ROLES.VENDOR_PUBLISH).length > 0
    },
    {
      label:  NAVIGATIONS.COMPARISON,
      icon: <TableChartIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_VIEW || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_EXPORT || role === ROLES.VENDOR_PUBLISH).length > 0
    },
    {
      label: NAVIGATIONS.AUTOMATION,
      icon: <TableChartIcon />,
      visibility: userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_VIEW || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_EXPORT || role === ROLES.VENDOR_PUBLISH).length > 0
    },
    {
      label:  NAVIGATIONS.JOB_DOCS,
      icon: <FolderSharedIcon />,
      visibility : userRole.filter((role) => role === ROLES.SUPER_ADMIN || role === ROLES.VENDOR_VIEW || role === ROLES.VENDOR_EDIT || role === ROLES.VENDOR_EXPORT || role === ROLES.VENDOR_PUBLISH).length > 0
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigateToRoute = (route: string) => {
    setHeaderName(route);
    dispatch(AnalyticsActions.resetFilter());
    if(route !== NAVIGATIONS.CLIENT_REQUESTS){
      dispatch(ClientRequestActions.setSelectedCompany(null));
    }
    switch (route) {
      case  NAVIGATIONS.VENDORS: {
        history.push("/vendors");
        break;
      }
      case  NAVIGATIONS.CLIENTS: {
        history.push("/clients");
        break;
      }
      case NAVIGATIONS.CLIENT_REQUESTS: {
        history.push("/client-requests");
        break;
      }
      case NAVIGATIONS.CLIENT_ANALYTICS: {
        history.push("/analytics-clients/list");
        break;
      }
      case NAVIGATIONS.VENDOR_ANALYTICS: {
        history.push("/analytics-vendor/list");
        break;
      }
      case NAVIGATIONS.MESSAGING: {
        history.push("/messaging");
        break;
      }
      case NAVIGATIONS.COMMUNICATIONS: {
        history.push("/client-vendor-messages");
        break;
      }
      case NAVIGATIONS.USER_INVITES: {
        history.push("/userInvites");
        break;
      }
      case NAVIGATIONS.TAGS: {
        history.push("/vendorTags");
        break;
      }
      case NAVIGATIONS.SETTINGS: {
        history.push("/settings");
        break;
      }
      case NAVIGATIONS.COMPARISON: {
        history.push("/comparison");
        break;
      }
      case NAVIGATIONS.AUTOMATION: {
        history.push("/automation");
        break;
      }
      case NAVIGATIONS.JOB_DOCS: {
        history.push("/job-docs");
        break;
      }
      default: {
        history.push("/");
      }
    }
  };

  const logout = () => {
    setLogout();
    history.push("/login");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon style={{ color: "#e24f55" }} />
          </IconButton>
          <Typography variant="h6" noWrap style={{color: "#FFF"}}>
            {headerName}
          </Typography>
          <div className={classes.toolbarButtons}>
            <Tooltip title="Logout" placement="bottom-start">
              <IconButton color="inherit" onClick={logout} edge="end">
                <ExitToAppIcon style={{ color: "#e24f55" }} />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {navLinks.map(link => (
            link.visibility &&
            <ListItem
              button
              key={link.label}
              onClick={() => navigateToRoute(link.label)}
            >
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText primary={link.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content} style={{"overflow": "hidden"}}>
        <div className={classes.toolbar} style={{"overflow": "auto"}} />
        <WrappedRoutes></WrappedRoutes>
      </main>
    </div>
  );
}
